<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Add New Tag</v-card-title>
        <v-card-text>
          <v-col cols="6">
            <v-text-field v-model="tag.tag" label="Name of the new Tag" hint="Try to keep them as short as possible :)"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn @click="postData" color="primary">Submit</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "TagAddNewPage",

  data() {
    return {
      tag: {
        tag: "",
      },
      loading: false,
    }
  },

  methods: {
    postData() {
      let self = this

      self.loading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/tags`, self.tag, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "New Tag Successfully Added",
                color: "info"
              })
              self.loading = false
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    },
  }
}
</script>

<style scoped>

</style>
